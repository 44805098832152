import ActionHandler from '@/js/components/forms/action_handler';
import InputFilter from '@/js/components/filters/inputFilter';
import SelectFilter from '@/js/components/filters/selectFilter';

export default class FilterForm extends ActionHandler {
  constructor(formEl) {
    super();

    this.dom = {
      formEl,
    };

    this.components = {
      inputFilters: [...document.querySelectorAll(`input.filter-item, input[form="${formEl.id}"].filter-item`)].map((field) => new InputFilter(field)),
      selectFilters: [...document.querySelectorAll(`select.filter-item, select[form="${formEl.id}"].filter-item`)].map((field) => new SelectFilter(field)),
    };

    this.props = {
      url: formEl.getAttribute('action') || '',
      action: formEl.querySelector('input[name="action"]') ? formEl.querySelector('input[name="action"]').value : 'filter',
      type: null,
      submitOnChange: true,
    };

    this.events = {
      onFormSubmit: this.onFormSubmit.bind(this),
    };

    this.mount();
  }

  mount() {
    this.dom.formEl.addEventListener('submit', this.events.onFormSubmit);

    this.components.inputFilters.forEach((filter) => {
      filter.dom.input.addEventListener('change', (e) => this.submit(e.currentTarget));
    });

    this.components.selectFilters.forEach((filter) => {
      filter.dom.select.addEventListener('change', (e) => this.submit(e.currentTarget));
    });
  }

  /**
   * @param e {Event}
   */
  onFormSubmit(e) {
    e.preventDefault();

    // Initialize form data
    const formData = new FormData(this.dom.formEl);

    this.send({ url: this.props.url, formData, action: this.props.action });
  }

  /**
   * @param submitter {HTMLElement|null}
   */
  submit(submitter = null) {
    if (this.props.submitOnChange) {
      this.dom.formEl.dispatchEvent(new SubmitEvent('submit', { submitter, cancelable: true }));
    }
  }

  reset() {
    [...this.components.inputFilters, ...this.components.selectFilters]
      .forEach((filter) => filter.reset());
  }
}

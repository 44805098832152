export default class SelectFilter {
  /**
   * @param select {HTMLSelectElement}
   */
  constructor(select) {
    this.dom = {
      select,
    };

    this.events = {
      onSelectChange: undefined,
    };

    this.mount();
  }

  mount() {
    if (this.dom.select) {
      this.dom.select.addEventListener('change', this.events.onSelectChange);
    }
  }

  reset() {
    this.dom.select.value = '';
  }
}

import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/airbnb.css';
import { Dutch } from 'flatpickr/dist/l10n/nl';
import { getCalendarDates } from '@/js/api/events/events';
import FilterForm from '@/js/components/forms/filter_form';

class Events {
  constructor() {
    this.dom = {
      datePicker: document.getElementById('date-picker-filter'),
      filterForm: document.getElementById('event-filter-form'),
    };

    this.components = {
      filterForm: null,
    };

    this.data = {
      flatpickerConfig: {
        mode: 'single',
        minDate: 'today',
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'd/m/Y',
        position: 'auto right',
        locale: Dutch,
        defaultDate: false,
        allowInput: false,
      },
    };

    this.mount();
  }

  async mount() {
    if (this.dom.datePicker) {
      // eslint-disable-next-line new-cap,no-new
      flatpickr(this.dom.datePicker, {
        ...this.data.flatpickerConfig,
        enable: await Events.getCalendarDates(),
      });
    }

    if (this.dom.filterForm) {
      this.components.filterForm = new FilterForm(this.dom.filterForm);
    }
  }

  static getCalendarDates() {
    return getCalendarDates()
      .then((response) => response.data)
      .catch((error) => {
        console.error(error.response);
      });
  }
}

if (document.getElementById('page-events-overview')) {
  // eslint-disable-next-line no-new
  new Events();
}

// components
import FormValidation from '@/js/components/forms/form_validation';
import GoogleMaps from '@/js/components/google_maps/map';

class EventsDetail {
  constructor() {
    this.dom = {
      mapEl: document.getElementById('event-map'),
      formSign: document.getElementById('form-event-signup'),
    };

    this.props = {
      mapMarker: null,
      mapOptions: null,
    };

    this.components = {
      googleMaps: null,
      signFormValidation: null,
    };

    this.events = {
      onMapLoaded: this.onMapLoaded.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.mapEl) {
      this.initMaps();
    }

    if (this.dom.formSign) {
      this.components.signFormValidation = new FormValidation(this.dom.formSign);
    }
  }

  initMaps() {
    this.props.mapOptions = {
      center: { lat: 50.91263082619794, lng: 3.647702889873862 },
    };
    // parse props
    const latitude = parseFloat(this.dom.mapEl.getAttribute('data-latitude'));
    const longitude = parseFloat(this.dom.mapEl.getAttribute('data-longitude'));

    this.props.mapOptions.center = { lat: latitude, lng: longitude };

    // listen to map loaded
    this.dom.mapEl.addEventListener('loaded', this.events.onMapLoaded);

    // create map instance
    this.components.googleMaps = new GoogleMaps(this.dom.mapEl, this.props.mapOptions);
  }

  onMapLoaded() {
    // Add a marker at the map's center coordinates
    const centerMarker = {
      position: this.props.mapOptions.center, // Use the center coordinates from mapOptions
    };

    this.components.googleMaps.addMarker(centerMarker);
  }
}

if (document.getElementById('page-events-detail')) {
  // eslint-disable-next-line no-new
  new EventsDetail();
}
